/* eslint-disable prettier/prettier */
/* eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const EmilyGlobalStyles = () => {
    const useClasses = makeStyles((theme) => ({
        '@global': {
          '*::-webkit-scrollbar': {
            width: '10px',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgb(189, 189, 196)',
            borderRadius: '20px',
            border: '3px solid rgb(206, 204, 201)',
          },
          '*::-webkit-scrollbar-track': {
            background: 'rgb(245, 243, 239)',
          
          },
        },
        totrec:{
          float:"left",
          marginTop:"20px"
        },
        maincontainer:{
          backgroundColor:"#ECF0F3",
          padding:"0.5%",
          height:"100%"
        },
        container:{
          backgroundColor:"#ECF0F3",
          padding:"0.5%",
        },
        title:{
            color: '#000',
            fontFamily: 'OpenSans-bold',
            fontWeight:"bold",
            fontSize: theme.typography.pxToRem(20),
            marginBottom: theme.typography.pxToRem(10),
            
        },
        Ctable:{
          marginTop: '1px',
          left: '9px',
          textAlign: 'center'
        },
        dollar:{
            padding:"6.8px"
        },
        dollar1:{
          padding:"4.8px"
        },
        dollartxtbox:{
            width:"1000px"
        },  
        bold: {
          fontFamily: 'OpenSans-Bold',
        },      
        semiBold: {
          fontFamily: 'OpenSans-semibold',
          
        },
        cardheading:{
            marginTop: '33px',
            marginBottom:'1%'
        },
        mr:{
          marginRight: '10px',
        },
        customercard:{
          float:"left",
          height:"350px"
        },
        editbttn:{
          marginLeft: '20px', 
          width: '8px', 
          padding: '3px'
        },
        cancelbttn:{
          color:"#ff3333",
          marginLeft: '10px', 
          width: '8px', 
          padding: '3px'
        },
        inputstyle:{
          fontWeight:"bold",
          marginBottom:"38px"
        },
        typostyle:{
          marginBottom:"24px"
        },
        inputcard:{
          display:"inline",
          marginTop:"30px"
        },
        boxcard:{marginTop:"22px"},
        mt2:{
          marginTop: theme.typography.pxToRem(10)
        },
        mt3:{
            marginTop: theme.typography.pxToRem(25),
        },
        mt5:{
          marginTop: theme.typography.pxToRem(48),
        },
        mb3:{
            marginBottom: theme.typography.pxToRem(16),
        },
        loaderModalCard: {
          '& div': {
            '& div': {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
          },
        },    
        textRight: {
          textAlign: 'right',
        },
        titlealign:{
            textAlign:'center'
          },
        filterbttn:{
          padding: '8px 10px',
          marginLeft: '20px',
          marginBottom: '20px'
        },  
      mL1: {
        marginLeft: '10px',
      },       
      mt9: {
        marginTop: theme.typography.pxToRem(8)
      },
      textFixHead: {

        '& th': {

            top: 0,
            zIndex: 10,
            backgroundColor: '#ffffff',
            fontFamily: 'OpenSans-bold',
            padding: '0.5rem 0.2rem',
            fontSize: '15px',
            fontWeight: '400',
            color: '#111111',
        },

        '& tbody': {
            '& tr': {

                backgroundColor: '#ffffff',
            },
            '& td': {
                fontFamily: 'OpenSans-semibold',
                padding: '0.9375rem 0.2rem',
            },
        },
    },
      padding0:{
        marginTop:'42px',
        padding:'10px',
        marginBottom:'0.5%'
      },  
        clrbutton:{
          width:"90px",
          padding: '7px 11px',
          marginLeft: '20px',
          marginTop:"17px",
          marginBottom: '20px',
          float: 'right'
        },
        applybttn:{
          width:"90px",
          padding: '7px 11px',
          marginLeft: '20px',
          marginTop:"17px",
          float: 'right'
        },
          floatLeft: {
            float: 'left',
          },
          floatRight: {
            float: 'right',
          },
          mb4: {
            marginBottom: theme.typography.pxToRem(10),
          },      
          sortimg:{
            marginTop: '5px', 
            marginLeft: '5px' 

          },
          sortimgstyle:{
            marginTop: '5px', 
            marginLeft: '5px' 
          },
          formLabel: {
            marginBottom: '0.2rem',
            color: '#000',
            marginTop:"3%",
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
          },
          inputcard:{
            height:'95%',
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%'
          },
          ellipsisCell: {
            maxWidth: '95px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          fixTableHeadAgrTrsRec: {
            overflowY: 'auto',
            maxHeight: '390px',
            '& th': {
              position: 'sticky',
              top: 0,
              backgroundColor: '#ffffff',
              fontFamily: 'OpenSans-bold',
              zIndex: 1,
              padding: '0.9375rem 0.2rem',
              whiteSpace: 'nowrap',
              fontSize: '15px',
              fontWeight: '400',
              color: '#111111',
            },
            '& tbody': {
              '& tr': {
                position: 'sticky',
                backgroundColor: '#ffffff',
              },
              '& td': {
                fontFamily: 'OpenSans-semibold',
                padding: '0.9375rem 0.2rem',
              },
            },
            disableme: {
              pointerEvents: 'none',
              opacity: 0.9,
            },
          },
          GridLoader: {
            textAlign: 'center',
            marginBottom: theme.typography.pxToRem(20),
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
            margin: '30px 0px',
          },
        card:{
            marginTop:"1%",
            height:'100%',
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
        },
        textBlue:{
            color: `${RACCOLOR.NORMAL_BLUE}`,
        },
        semiBold:{
            fontFamily: 'OpenSans-semibold',
            
        },
        whitebackground:{
          backgroundColor:"white"
        },
        textDarkSlateBlue:{
          color:"DarkSlateBlue"
        },
        customModal2: {
            '& div': {
              '& div': {
                maxWidth: '650px',
                maxHeight:'850px'
              },
            },
          },
          customModal1: {
            '& div': {
              '& div': {
                maxWidth: '300px',
              },
            },
          },
          customModal3: {
            '& div': {
              '& div': {
                maxWidth: '450px'
              },
            },
          },
          alignment:{
            textAlign:'center',
            marginTop:'20px'
          },
          pageLink:{
            position:'relative',
            display:'block',
            textDecoration:'none',
            padding:'5px 0px',
            width:'29px',
            textAlign:'center'
          },
          paginationPrevNext:{
            color:"#000",
            padding:'5px 17px',
            display:'inline-block'
          },
          paginationPrevNextDisabled:{
            color:'lightgray !important',
            padding:'5px 17px',
            display:'inline-block'
          },
          pageActive:{
            backgroundColor:'#2468FF',
            color:'#fff',
            borderRadius:'50%'
          },
          cursorPointer: {
            cursor: 'pointer',
          },
          modalcardpadding:{
            padding:'0px 6px'
          },
          mR1:{
            padding: '8px 10px',
            marginLeft: '20px',
            marginBottom: '20px'
          },
          txtbox:{
            width:"10px",
            padding:'0px 0px'
          },

        successCard: {
      '& div': {
        '& div': {
          maxWidth: "350px"
        },
      }
    },
    alertCardContent: {
      '& div': {
        marginBottom: '1rem',
        justifyContent: "center"
      },
    },
          cancelcontainer:{
            height: '120px', 
            paddingLeft: '10px'
          },
          cancelbuttonstyle:{
            marginLeft: '55%'
          },
          headingchargeback:{
            fontWeight:"bold",
            marginTop:'25px'
          },
          okbuttonstyle:{
            fontWeight:"bold",
            padding:"5px",
            width:"50px"
          },
          cancelbuttonstyle:{
            padding: '10px 5px',
            width: '80px',
            color: 'gray',
            border: '1px solid gray'
          },
          cancelchrg:{
            fontWeight:"bold",
            padding:"7px",
            float:'right',
            marginRight:"3px",
            backgroundColor:"#ff3333",
            color:'white'
          },
          clrbutton:{
            marginTop:"17px",
            width:"90px",
            padding: '7px 11px',
            float:"right"

          },
          Savedropdownbutton:{
            padding: '10px 5px', 
            width: '80px',
            marginLeft: '4%'
          },
          pb4: {
            paddingBottom: theme.typography.pxToRem(24),
          },
          mx1: {
            marginRight: theme.typography.pxToRem(4),
            marginLeft: theme.typography.pxToRem(4),
          },
          fixTableHeadAgrTrsRec: {
            overflowY: 'auto',
            '& th': {
              position: 'sticky',
              top: 0,
              backgroundColor: '#ffffff',
              fontFamily: 'OpenSans-bold',
              zIndex: 1,
              padding: '0.5rem 0.2rem',
              whiteSpace: 'nowrap',
              fontSize: '15px',
              fontWeight: '400',
              color: '#111111',
            },
      
            '& tbody': {
              '& tr': {
                position: 'sticky',
                backgroundColor: '#ffffff',
              },
              '& td': {
                fontFamily: 'OpenSans-semibold',
                padding: '0.9375rem 0.2rem',
              },
            },
      
            disableme: {
              pointerEvents: 'none',
              opacity: 0.9,
            },
          },
      
          fixTableHeadAgrTrsRec1: {
            overflowY: 'hidden',
            '& th': {
              position: 'sticky',
              top: 0,
              backgroundColor: '#ffffff',
              fontFamily: 'OpenSans-bold',
              zIndex: 1,
              padding: '0.5rem 0.2rem',
              whiteSpace: 'nowrap',
              fontSize: '15px',
              fontWeight: '400',
              color: '#111111',
            },
      
            '& tbody': {
              '& tr': {
                position: 'sticky',
                backgroundColor: '#ffffff',
              },
              '& td': {
                fontFamily: 'OpenSans-semibold',
                padding: '0.9375rem 0.2rem',
              },
            },
           disableme: {
            pointerEvents: 'none',
            opacity: 0.9,
          },
    
        },
          errorInfoCard: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width:'100%',
            height: '200px',
            borderRadius: '15px'
        },
    }));
    const classes = useClasses();
    return classes;    
}
