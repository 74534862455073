import {
  CardContent,
  Grid,
  RACButton,
  RACSelect,
  RACTextbox,
  Card,
} from '@rentacenter/racstrap';
import { reasonOptions } from '../../constants/constants';
import { TaxContext } from '../Taxmgmt/TaxContext';
import React, { useContext } from 'react';

export default function Taxsearchhead(props: any) {
  const {
    validationData,
    dropDownloading,
    TaxSearchObj,
    classes,
    setTaxSearchObj,
    clearButtonDisable,
  } = useContext(TaxContext);

  return (
    <Grid container md={12}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container md={12} spacing={2}>
            <Grid item md={2} xs={12} lg={2} sm={6}>
              <RACTextbox
                type="text"
                name="lastName"
                inputlabel="Last Name"
                value={TaxSearchObj.lastName}
                maxlength={30}
                OnChange={(e: any) => {
                  props?.handleInputChange(e, /^[A-Za-z]+$/, /[^A-Za-z]/g);
                  props?.handleOnChange(e);
                }}
                OnKeydown={(e: any) => {
                  props?.onKeyPress(e);
                }}
              />
            </Grid>
            <Grid item md={2} xs={12} lg={2} sm={6}>
              <RACTextbox
                type="text"
                name="firstName"
                inputlabel="First Name"
                maxlength={30}
                value={TaxSearchObj.firstName}
                OnKeydown={(e: any) => {
                  props?.onKeyPress(e);
                }}
                OnChange={(e: any) => {
                  props?.handleInputChange(e, /^[A-Za-z]+$/, /[^A-Za-z]/g);
                  props?.handleOnChange(e);
                }}
              />
            </Grid>
            <Grid item md={2} xs={12} lg={2} sm={6}>
              <RACSelect
                name="Status"
                inputLabel="Status"
                loading={dropDownloading}
                options={reasonOptions}
                defaultValue={TaxSearchObj.status}
                onChange={(e) => {
                  setTaxSearchObj({
                    ...TaxSearchObj,
                    status: e?.target?.value,
                  });
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={6}
              lg={6}
              sm={6}
              className={`${classes.floatRight}`}
            >
              <RACButton
                color="primary"
                variant="contained"
                size="small"
                disabled={validationData ? false : true}
                className={`${classes.floatRight} ${classes.applybttn}`}
                style={{ marginLeft: '20px' }}
                onClick={() => {
                  props?.fetchTaxSearchData();
                }}
              >
                Apply
              </RACButton>
              <RACButton
                color="primary"
                variant="outlined"
                size="large"
                style={clearButtonDisable ? {} : { color: '#7FA6FF' }}
                className={`${classes.floatRight} ${classes.clrbutton}`}
                disabled={clearButtonDisable ? false : true}
                onClick={() => {
                  props?.clearSearch();
                }}
              >
                Clear
              </RACButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
