/*eslint-disable*/
import {
  CardContent,
  Grid,
  RACTable,
  CircularProgress,
} from '@rentacenter/racstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card } from '@rentacenter/racstrap';
import { TaxContext } from '../Taxmgmt/TaxContext';
import React, { useContext } from 'react';
import ErrorContent from '../Taxmgmt/ErrorMsgContent';
import { getTaxSummary } from '../../api/user';
import { buildPayload } from '../../constants/constants';

export default function Taxsearchbody(props: any) {
  const {
    classes,
    offset,
    loader,
    viewableRecords,
    hasMore,
    pageDisplay,
    displaySearch,
    fetchLoader,
    setfetchLoader,
    sethasMore,
    TaxSearchObj,
    setOffset,
    setViewableRecords,
    displaySearchResults,
  } = useContext(TaxContext);

  const renderErrorContent = (content: any, status: any) => (
    <ErrorContent content={content} status={status} style={classes.mt3} />
  );
  const fetchNextData = async () => {
    try {
      sethasMore(true);
      setfetchLoader(true);
      const payload = buildPayload(TaxSearchObj, offset.toString());
      const fetchedData: any = await getTaxSummary(payload);
      if (fetchedData?.status === 200) {
        setOffset(offset + 20);
        setfetchLoader(false);
        setViewableRecords((pre: any) => [
          ...pre,
          ...fetchedData.data.taxExemptCustomers,
        ]);
        if (fetchedData.data?.taxExemptCustomers.length !== 20) {
          setfetchLoader(false);
          sethasMore(false);
        }
      } else {
        sethasMore(false);
      }
    } catch (e: any) {
      sethasMore(false);
    }
  };

  const renderTable = () => (
    <>
      {viewableRecords.length > 18 ? (
        <InfiniteScroll
          next={fetchNextData}
          dataLength={viewableRecords?.length}
          hasMore={hasMore}
          height={displaySearch ? '65vh' : viewableRecords?.length > 19 ? '80vh' : 'auto'}
          loader={
            fetchLoader ? (
              <Grid className={classes.GridLoader}>
                <CircularProgress></CircularProgress>
              </Grid>
            ) : null
          }
        >
          <RACTable
            className={`${classes.textFixHead}`}
            renderTableHead={props?.renderTaxTableHeadFn}
            renderTableContent={props?.renderTaxTableContentFn}
            stickyHeader
          />
        </InfiniteScroll>
      ) : (
        <RACTable
        className={`${classes.textFixHead}`}
        renderTableHead={props?.renderTaxTableHeadFn}
        renderTableContent={props?.renderTaxTableContentFn}
        stickyHeader
        />
      )}
    </>
  );
  return (
    <> 
    {displaySearchResults ? (
        <Grid
          container
          md={12}
          className={displaySearch === true ? `${classes.mt3}` : ''}
        >
           {loader ? (
        <Card className={classes.errorInfoCard} style={{ paddingLeft: '2%',marginTop:"1%" }}>
          <CardContent>
            <CircularProgress />
          </CardContent>
        </Card>
      ) :
          <Card className={classes.card}>
            <CardContent>
              <Grid
                className={
                  pageDisplay != 200
                    ? classes.fixTableHeadAgrTrsRec1
                    : classes.fixTableHeadAgrTrsRec
                }
              >
                {pageDisplay === 204 ? (
                  renderErrorContent('No Records Found', 204)
                ) : pageDisplay === 200 ? (
                  renderTable()
                ) : pageDisplay == 400 ? (
                  renderErrorContent('Bad Request', 400)
                ) : pageDisplay == 500 ? (
                  renderErrorContent('Something Went Wrong', 500)
                ) : (
                  <></>
                )}
              </Grid>
            </CardContent>
          </Card>}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
