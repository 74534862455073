import { taxObj } from '../interface';

export const ProductType = 'RPDPLACEHOLDER';

export enum ProductPermissions {
  PLACEHOLDER = 'PLACEHOLDER',
}
export const TaxExemptGridHeadings = [
  { heading: 'Last Name' },
  { heading: 'First Name' },
  { heading: 'Date of Birth' },
  { heading: 'SSN' },
  { heading: 'Email' },
  { heading: 'Address' },
  { heading: 'City' },
  { heading: 'State' },
  { heading: 'Zip Code' },
  { heading: 'Tax Exempt Status' },
];
export const reasonOptions = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Pending',
    value: 'PEND',
  },
  {
    label: 'Exempt',
    value: 'APP',
  },
];
export function buildPayload(TaxSearchObj: taxObj, offset: any) {
  const { lastName, firstName, status, limit } = TaxSearchObj;

  return {
    lastName: lastName || null,
    firstName: firstName || null,
    status: status || null,
    offset: offset,
    limit: limit || null,
  };
}
export function formatDate(date: string) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return [month, day, '****'].join('/');
}
export const getTaxReasons = (reasons: string) => {
  switch (reasons) {
    case 'Approved':
      return 'Exempt';
    case 'Pending':
      return 'Pending';
    default:
      return reasons;
  }
};
