import React, { createContext, useState } from 'react';
import { taxObj } from '../../interface';
import { EmilyGlobalStyles } from '../../Styles/emilystyles';
export const TaxContext = createContext<any>('');
export function TaxProvider(props: any) {
  const classes = EmilyGlobalStyles();
  const [TaxSearchObj, setTaxSearchObj] = useState<taxObj>({
    lastName: '',
    firstName: '',
    status: 'ALL',
    limit: '20',
    offset: '0',
  });

  const clearButtonDisable: boolean =
    TaxSearchObj?.lastName?.trim() != '' ||
    TaxSearchObj?.firstName?.trim() != '' ||
    TaxSearchObj?.status?.trim() != 'ALL';

  const [displaySearch, setDisplaySearch] = useState<boolean>(false);
  const [displaySearchResults, setDisplaySearchResults] =
    useState<boolean>(true);
  const [filterDisable, setFilterDisable] = useState<boolean>(true);
  const [pageDisplay, setPageDisplay] = useState(200);
  const [offset, setOffset] = useState<any>(20);
  const [loader, setLoader] = useState<boolean>(false);
  const limit: any = 20;
  const [ErrPopup, setErrPopup] = useState(false);
  const [manageErrMsg, setmanageErrMsg] = useState('Something went wrong');
  const [dropDownloading, setDropDownloading] = useState<boolean>();
  const [totalRecords, setTotalRecords] = useState<number>();
  const [pageCount, setPageCount] = useState<number>(1);
  const [viewableRecords, setViewableRecords] = useState<Array<any>>([]);
  const [hasMore, sethasMore] = useState(true);
  const [fetchLoader, setfetchLoader] = useState(false);
  const [Sort, setSort] = useState('asc');
  const [isSort, setisSort] = useState('asc');
  const validationData: boolean =
    ((TaxSearchObj?.firstName?.trim()?.length ?? 0) > 2 &&
      TaxSearchObj?.lastName?.trim()?.length > 2) ||
    ((TaxSearchObj?.lastName?.trim()?.length ?? 0) > 2 &&
      TaxSearchObj?.firstName?.trim()?.length == 0) ||
    ((TaxSearchObj?.firstName?.trim()?.length ?? 0) > 2 &&
      TaxSearchObj?.lastName?.trim()?.length == 0) ||
    TaxSearchObj?.status?.trim() != 'ALL';
  return (
    <div>
      <TaxContext.Provider
        value={{
          classes,
          TaxSearchObj,
          setTaxSearchObj,
          displaySearch,
          setDisplaySearch,
          displaySearchResults,
          setDisplaySearchResults,
          filterDisable,
          setFilterDisable,
          pageDisplay,
          setPageDisplay,
          offset,
          setOffset,
          limit,
          ErrPopup,
          setErrPopup,
          loader,
          setLoader,
          fetchLoader,
          setfetchLoader,
          manageErrMsg,
          setmanageErrMsg,
          dropDownloading,
          setDropDownloading,
          totalRecords,
          setTotalRecords,
          pageCount,
          setPageCount,
          viewableRecords,
          setViewableRecords,
          Sort,
          setSort,
          isSort,
          setisSort,
          validationData,
          hasMore,
          sethasMore,
          clearButtonDisable,
        }}
      >
        {props.children}
      </TaxContext.Provider>
    </div>
  );
}
