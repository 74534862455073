import axios, { CancelTokenSource } from 'axios';
import { Auth } from '@aws-amplify/auth';

import { ApiUrls, appConfig } from '../config/app-config';

async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

export async function client(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  apiUrl: ApiUrls[keyof ApiUrls] = appConfig.apiUrls.micro,
  AccessToken = false
) {
  const jwtToken = await getJWToken();

  const headers: any = {
    'Content-Type': 'application/json; charset=UTF-8',
  };
  if (AccessToken) {
    const accessToken =
      window.localStorage.getItem('ac') || (await getAccessToken());
    headers.accessToken = accessToken;
    headers.storeNumber = window.sessionStorage.getItem('storeNumber');
  }

  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  const requestConfig: any = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }

  return axios(`${apiUrl}/${endpoint}`, requestConfig).then(
    (response) => response.data
  );
}

export async function clientCall(
  endpoint: string,
  requestBody: any,
  apiUrl: any,
  needAccessToken = true
) {
  try {
    const jwtToken = await getJWToken();
    // const accessToken= 'eyJraWQiOiJwQTJVaHZTOXJPK3lIbGtEWFdzOElKVjZxcExUamJHRkdaU05NU0xBYXZrPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzNjM2ZmQ4ZC0yYmIxLTQ3YTQtOGIxNy0wMjM2ZmFhNDI2ZDIiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfRHlnd1oxQXVtX09rdGEiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfRHlnd1oxQXVtIiwidmVyc2lvbiI6MiwiY2xpZW50X2lkIjoiNmVuODF0amF0Nmo2bmFxODM3ZGR1NWFvb3YiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNzA1MDQ0NTgzLCJleHAiOjE3MDUwNzMzODMsImlhdCI6MTcwNTA0NDU4MywianRpIjoiNWU1MjNmZjEtNWQ4YS00Njg5LTkxN2UtODA1ZmMyNDMxOTY0IiwidXNlcm5hbWUiOiJPa3RhXzQ3MTQwMDdAcmVudGFjZW50ZXIuY29tIn0.N6i0_XVQ1qDivJkigRkEcXkXvVJvXENLeaeWjdyx3RmeK8SzmrhtovzXDgq7V3DbxF7nMy4Hnziiix3Pa_uLfvGbUmRIHvLUwA31TamEWCag8i2secWi5QvJpIDfxM_nM30gfXLF9RDKR-SntWY0oqe1u1ofThSAtMDxO7JQt0x0_RtRiPYNbefNH_yHrT1RpUk-V4JOK8zKJQlp03AEDGlDrpbqED2lCGkrcM2Mjib3AMIFWxIRE4pYDtW3xetWreVTkzyfIRP45Bd7zgdCeYfA1uMZH7MSzejYVUKEsqzdpLqOmmQyIGWFxrLggvuptUok51GCKNDzQiPd2yMUzQ';
    // const jwtToken = 'eyJraWQiOiJIQlMyV3FnR0FsU1M5UkJBSUxDTTZ3ZFwveHZcL0I0a1ViUHhRZkg5bXlPNTA9IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiTldZaUJOMU5jV1Y5Q3R6UUdCb0llUSIsImN1c3RvbTplbXBsb3llZUlkIjoiNDcxNDAwNyIsInN1YiI6IjM2MzZmZDhkLTJiYjEtNDdhNC04YjE3LTAyMzZmYWE0MjZkMiIsImNvZ25pdG86Z3JvdXBzIjpbInVzLWVhc3QtMV9EeWd3WjFBdW1fT2t0YSJdLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImN1c3RvbTpsYXN0TmFtZSI6IkxuYW1lIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfRHlnd1oxQXVtIiwiY29nbml0bzp1c2VybmFtZSI6Ik9rdGFfNDcxNDAwN0ByZW50YWNlbnRlci5jb20iLCJub25jZSI6IjlpQkRpcUlpQmdtS1pOOVpIdFUyelJmUUROUGtSOEFVcGZ2MktxM1VwUmFmRUx0Y3h2dXdFMWxjOWU4SDVsMGVpWXRMV1VKeU9jdk5mdjJPOWVXQUl0bjlLVml1SWNoS3J5NlVSNG5TZGJpN0VrT1V0ZlhrSTFvc0VwVDN3NnZaYVU3a1ZKeDdDVWp4Um9ueXlqYVNIaXZiSU44TlFtbDktZzl1a1FQODhjayIsImF1ZCI6IjZlbjgxdGphdDZqNm5hcTgzN2RkdTVhb292IiwiaWRlbnRpdGllcyI6W3sidXNlcklkIjoiNDcxNDAwN0ByZW50YWNlbnRlci5jb20iLCJwcm92aWRlck5hbWUiOiJPa3RhIiwicHJvdmlkZXJUeXBlIjoiU0FNTCIsImlzc3VlciI6Imh0dHA6XC9cL3d3dy5va3RhLmNvbVwvZXhrMXZjNWJzazQwdGpvQUwwaDgiLCJwcmltYXJ5IjoidHJ1ZSIsImRhdGVDcmVhdGVkIjoiMTcwNDI5NzE5MzU1OSJ9XSwiY3VzdG9tOmZpcnN0TmFtZSI6IjQ3MTQwMDciLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTcwNTA0NDU4MywiZXhwIjoxNzA1MDczMzgzLCJpYXQiOjE3MDUwNDQ1ODMsImp0aSI6IjgxODA1YzgwLTE3ODktNGM1ZS05YTIxLWE2NDc4YmE0OTJlNCIsImVtYWlsIjoicnBhZC1va3RhcHJldmlld0ByZW50YWNlbnRlci5jb20ifQ.sb7x-Np8MBJXSVckb7EsUUR_8fXy38qrFmA-GUC3bpX7Gv7hvXPl1OSNJ1jxUF0TCicNiDJZPyHfqQ03JPegqxnlm3wSXW-1I9TZ3DC-YdAoTggrIpOLhTS_Xu6FwONPLIFJBHHDw-uwc9skWtbg3dC6p52fevAFeo7Pd0Hy74AzRbY0G5LoTWIemsN-wWcRY-VUK-NMyigZZd2pQJTiu8S7cF2ARFj0nTzixVI6C6PC95eRtsgilaDHWfr689m_9Si9lxEf8Kf1p45c-45WjGFqOT60qGPicWGXS4rk2mULpgY3FhHC-FVcj-0hX-_xDk_FOPFMnJcV4CEvck_-zw';

    const headers: any = {
      'Content-Type': 'application/json; charset=UTF-8',
      storeNumber: '04714',
    };

    if (needAccessToken) {
      headers.AccessToken = await getAccessToken();
      //headers.AccessToken = accessToken;
    }
    if (jwtToken) {
      headers.Authorization = `Bearer ${jwtToken}`;
    }

    const requestConfig: any = {
      method: requestBody.method,
      headers: {
        ...headers,
      },
    };
    if (requestBody.payload) {
      requestConfig.data = JSON.stringify(requestBody.payload);
    }
    return await axios(`${apiUrl}/${endpoint}`, requestConfig).then(
      (response) => response
    );
  } catch (e: any) {
    return e.response;
  }
}

async function getAccessToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getAccessToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

export const getCancelTokenSource = (): CancelTokenSource =>
  axios.CancelToken.source();
