import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Sortup } from '../../Assets/sort-up-icon.svg';
import { ReactComponent as Sortdown } from '../../Assets/Sort.svg';
import { ReactComponent as Filterdown } from '../..//Assets/down-arrow-filter.svg';
import { ReactComponent as Filterup } from '../..//Assets/up-arrow-filter.svg';
import { ReactComponent as Filter } from '../..//Assets/Filter.svg';
import { TaxExemptGridHeadings } from '../../constants/constants';
import Taxsearchhead from './TaxSearchHead';
import { getTaxReasons, formatDate } from '../../constants/constants';
import {
  Grid,
  Typography,
  RACButton,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { TaxContext } from './TaxContext';
import { EmilyGlobalStyles } from '../../Styles/emilystyles';
import TaxSearchBody from '../../components/Taxmgmt/TaxSearchBody';
import { getTaxSummary } from '../../api/user';
export default function TaxExempt() {
  const history = useHistory();
  const classes = EmilyGlobalStyles();
  const {
    TaxSearchObj,
    setTaxSearchObj,
    displaySearch,
    setDisplaySearch,
    displaySearchResults,
    viewableRecords,
    setViewableRecords,
    Sort,
    setSort,
    isSort,
    setisSort,
    setPageDisplay,
    setLoader,
    sethasMore,
    setDisplaySearchResults,
    setFilterDisable,
    validationData,
  } = useContext(TaxContext);

  useEffect(() => {
    fetchTaxSearchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderHelperHead = (obj: any) => (
    <>
      {obj.heading == 'Last Name' ? (
        <RACTableCell
          className={`${classes.titlealign}`}
          style={{ textAlign: 'left' }}
        >
          {' '}
          {obj.heading}
          {viewableRecords?.length <= 1 ? (
            <></>
          ) : isSort == 'asc' ? (
            <Sortdown
              className={` ${classes.sortimg} ${classes.textRight} ${classes.cursorPointer} `}
              onClick={() => sortGrid('desc', 'lastName')}
            />
          ) : (
            <Sortup
              className={` ${classes.sortimg} ${classes.textRight} ${classes.cursorPointer} `}
              onClick={() => sortGrid('asc', 'lastName')}
            />
          )}
        </RACTableCell>
      ) : (
        <RACTableCell
          className={`${classes.titlealign}`}
          style={{ textAlign: 'left' }}
        >
          {' '}
          {obj.heading}
          {viewableRecords?.length <= 1 ? (
            <></>
          ) : Sort == 'asc' ? (
            <Sortdown
              className={` ${classes.sortimg} ${classes.textRight} ${classes.cursorPointer} `}
              onClick={() => sortGrid('desc', 'firstName')}
            />
          ) : (
            <Sortup
              className={` ${classes.sortimg} ${classes.textRight} ${classes.cursorPointer} `}
              onClick={() => sortGrid('asc', 'firstName')}
            />
          )}
          {/* </a> */}
        </RACTableCell>
      )}
    </>
  );

  const renderTaxTableHeadFn = () => (
    <>
      {TaxExemptGridHeadings.map((obj: any) =>
        obj.heading === 'Last Name' || obj.heading === 'First Name' ? (
          renderHelperHead(obj)
        ) : (
          <RACTableCell
            key={obj.heading}
            className={`${classes.titlealign}`}
            style={{ textAlign: 'left' }}
          >
            {obj.heading}
          </RACTableCell>
        )
      )}
    </>
  );
  const ascsort = (key: any) => {
    viewableRecords.sort(function (a: any, b: any) {
      const x =
          a[key] !== undefined && a[key] !== null ? a[key].toUpperCase() : '',
        y = b[key] !== undefined && b[key] !== null ? b[key].toUpperCase() : '';
      return x == y ? 0 : x > y ? 1 : -1;
    });
    key == 'lastName' ? setisSort('desc') : setSort('desc');
  };

  const descsort = (key: any) => {
    viewableRecords.sort(function (a: any, b: any) {
      const x =
          a[key] !== undefined && a[key] !== null ? a[key].toUpperCase() : '',
        y = b[key] !== undefined && b[key] !== null ? b[key].toUpperCase() : '';
      return x == y ? 0 : x > y ? -1 : 1;
    });
    key == 'lastName' ? setisSort('asc') : setSort('asc');
  };
  const sortGrid = (columnToSort: string, key: string) => {
    const sortedData: any =
      columnToSort != 'asc' ? ascsort(key) : descsort(key);
    setViewableRecords([...sortedData]);
  };
  const renderContentHelper = (obj: any) => (
    <>
      <RACTableCell
        style={{ textAlign: 'left', width: '13%' }}
        className={`${classes.textDarkSlateBlue} ${classes.semiBold} ${classes.titlealign} ${classes.ellipsisCell}`}
      >
        {obj.addresses[0].addressLine1 ? obj.addresses[0].addressLine1 : '-'}
      </RACTableCell>
      <RACTableCell
        style={{ textAlign: 'left', width: '8%' }}
        className={`${classes.textDarkSlateBlue} ${classes.semiBold} ${classes.titlealign}`}
      >
        {obj.addresses[0].city ? obj.addresses[0].city : '-'}
      </RACTableCell>
      <RACTableCell
        style={{ textAlign: 'left', width: '8%' }}
        className={`${classes.textDarkSlateBlue} ${classes.semiBold} ${classes.titlealign}`}
      >
        {obj.addresses[0].stateAbbreviation
          ? obj.addresses[0].stateAbbreviation
          : '-'}
      </RACTableCell>
      <RACTableCell
        style={{ textAlign: 'left', width: '10%' }}
        className={`${classes.textDarkSlateBlue} ${classes.semiBold} ${classes.titlealign}`}
      >
        {obj.addresses[0].postalCode ? obj.addresses[0].postalCode : '-'}
      </RACTableCell>
      <RACTableCell
        style={{ textAlign: 'left', width: '12%' }}
        className={`${classes.textDarkSlateBlue} ${classes.semiBold} ${classes.titlealign}`}
      >
        {obj.taxExemptStatus ? getTaxReasons(obj.taxExemptStatus) : '-'}
      </RACTableCell>
    </>
  );
  const redirect = (obj: any) => {
    history.push({
      pathname: `/operations/customer/details/${obj?.customerId}/taxexempt`,
    });
  };

  const renderTaxTableContentFn = () => (
    <>
      {viewableRecords?.map((obj: any, index: any) => {
        return (
          <React.Fragment key={index}>
            <RACTableRow
              key={index}
              className={` ${classes.titlealign} ${classes.whitebackground} ${classes.cursorPointer}`}
              onClick={() => {
                redirect(obj);
              }}
            >
              <RACTableCell
                style={{ textAlign: 'left', width: '9%' }}
                className={`${classes.textBlue} ${classes.semiBold} ${classes.cursorPointer} ${classes.titlealign} `}
                onClick={() => {
                  redirect(obj);
                }}
              >
                {obj.lastName}
              </RACTableCell>
              <RACTableCell
                style={{ textAlign: 'left', width: '9%' }}
                className={`${classes.textBlue} ${classes.semiBold} ${classes.titlealign}`}
                onClick={() => {
                  redirect(obj);
                }}
              >
                {obj.firstName ? obj.firstName : '-'}
              </RACTableCell>
              <RACTableCell
                style={{ textAlign: 'left', width: '9%' }}
                className={`${classes.textDarkSlateBlue} ${classes.semiBold} ${classes.titlealign}`}
              >
                {obj.dateOfBirth ? formatDate(obj.dateOfBirth) : '-'}
              </RACTableCell>
              <RACTableCell
                style={{ textAlign: 'left', width: '8%' }}
                className={`${classes.textDarkSlateBlue} ${classes.semiBold} ${classes.titlealign}`}
              >
                {obj.taxId ? obj.taxId.replace(/\*/g, '') : '-'}
              </RACTableCell>
              <RACTableCell
                style={{ textAlign: 'left', width: '13%' }}
                className={`${classes.textDarkSlateBlue} ${classes.semiBold} ${classes.titlealign}`}
              >
                {obj.emailAddress ? obj.emailAddress : '-'}
              </RACTableCell>
              {renderContentHelper(obj)}
            </RACTableRow>
          </React.Fragment>
        );
      })}
    </>
  );

  const payload = {
    lastName: TaxSearchObj?.lastName || null,
    firstName: TaxSearchObj?.firstName || null,
    status: TaxSearchObj.status,
    limit: TaxSearchObj.limit,
    offset: '0',
  };
  async function fetchTaxSearchData() {
    try {
      setDisplaySearchResults(true);
      setDisplaySearch(false);
      setFilterDisable(false);
      setLoader(true);
      sethasMore(true);
      const fetchedData: any = await getTaxSummary(payload);
      setLoader(false);
      setDisplaySearch(false);
      setFilterDisable(false);
      fetchedData?.status === 200
        ? handleSuccessPageDisplay(fetchedData)
        : handleFailurePageDisplay(fetchedData);
    } catch (error) {
      handleFetchError();
    }
  }
  function handleSuccessPageDisplay(fetchedData: any) {
    setDisplaySearchResults(true);
    setPageDisplay(
      fetchedData.data.taxExemptCustomers.length !== 0 ? 200 : 204
    );
    setViewableRecords(fetchedData?.data.taxExemptCustomers);
  }

  function handleFailurePageDisplay(fetchedData: any) {
    setPageDisplay(fetchedData.status === 400 ? 400 : 500);
    setDisplaySearchResults(true);
  }

  function handleFetchError() {
    setDisplaySearchResults(true);
    setLoader(false);
    setPageDisplay(500);
  }
  function onKeyPress(e: any) {
    validationData && e.key == 'Enter' ? fetchTaxSearchData() : null;
  }
  function clearSearch() {
    setTaxSearchObj({
      ...TaxSearchObj,
      lastName: '',
      firstName: '',
      status: 'ALL',
    });
  }
  function handleOnChange(e: any) {
    setTaxSearchObj({
      ...TaxSearchObj,
      [e?.target?.name]: e?.target?.value,
    });
  }
  const handleInputChange = (e: any, regex: any, replace: any) => {
    !regex.test(e.target.value)
      ? (e.target.value = e.target.value.replace(replace, ''))
      : null;

    handleOnChange(e);
  };
  return (
    <Grid
      container
      md={12}
      className={`${classes.mb3} ${classes.maincontainer}`}
    >
      <Grid container>
        <Grid item md={6}>
          <Typography
            className={`${classes.floatLeft} ${classes.mt5} ${classes.title}`}
          >
            {' '}
            Tax Exempt Customers
          </Typography>
        </Grid>
        <Grid item md={6} lg={6} sm={6} xs={6}>
          <RACButton
            color="primary"
            variant="contained"
            size="small"
            className={`${classes.mt9} ${classes.mr} ${classes.floatRight} ${classes.padding0}`}
            onClick={() => {
              setDisplaySearch(!displaySearch);
            }}
          >
            <Filter className={`${classes.mr}`} />
            Filter
            {displaySearch ? (
              <Filterup className={`${classes.mL1}`} />
            ) : (
              <Filterdown className={`${classes.mL1}`} />
            )}
          </RACButton>
        </Grid>
      </Grid>
      {displaySearch ? (
        <Taxsearchhead
          onKeyPress={onKeyPress}
          handleInputChange={handleInputChange}
          handleOnChange={handleOnChange}
          fetchTaxSearchData={fetchTaxSearchData}
          clearSearch={clearSearch}
        />
      ) : (
        <></>
      )}

      {displaySearchResults ? (
        <TaxSearchBody
          renderTaxTableHeadFn={renderTaxTableHeadFn}
          renderTaxTableContentFn={renderTaxTableContentFn}
        />
      ) : (
        <></>
      )}
    </Grid>
  );
}
