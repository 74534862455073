import { AxiosResponse } from 'axios';
import { APIError } from '../types/types';

export const sanitizeURL = (url: string): string => {
  if (!url) {
    return '';
  }

  const badURLRegex = RegExp('^((https)|(http)):/{3,}');
  const isBadURL = badURLRegex.test(url);

  if (isBadURL) {
    return 'https://' + url.replace(badURLRegex, '');
  }

  return url;
};

export const getErrorMessage = (response?: AxiosResponse<APIError>) => {
  if (response?.status === 400 && response?.data.errors[0].code) {
    return response?.data.errors[0].message;
  }

  return null;
};
