import React from 'react';
import { Redirect, Route } from 'react-router-dom';
//import { useHistory } from 'react-router-dom';
import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
//import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
import { TaxProvider } from '../components/Taxmgmt/TaxContext';
import TaxExempt from '../components/Taxmgmt/TaxSearch';

export const routesTestId = 'routesTestId';

export const Routes = () => (
  <UserProvider>
    {/* <PermissionsProvider> */}
    <UserFetcher />
    <div data-testid={routesTestId}>
      {/* <Switch> */}
      <Route exact path={AppRoute.Index}>
        <Redirect to={AppRoute.taxSearch} />
      </Route>
      <TaxProvider>
        <Route exact path={AppRoute.taxSearch}>
          <TaxExempt />
        </Route>
      </TaxProvider>
      {/* </Switch> */}
    </div>
    {/* </PermissionsProvider> */}
  </UserProvider>
);
